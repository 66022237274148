import { Route, Routes, useLocation } from 'react-router-dom';

import './App.css';
import Navigation from './components/Navigation';
import DatasetsRoutes from './routes/Datasets/DatasetsRoutes';
import FilesRoutes from './routes/Files/FilesRoutes';
import Login from './routes/Login/Login';
import ModelsRoutes from './routes/Models/ModelsRoutes';
import PredictionsRoutes from './routes/Predictions/PredictionsRoutes';
import JobsRoutes from './routes/Job Queue/JobsRoutes';
import UserDirectory from './routes/User Directory/pages/UserDirectory';
import ReadAcrossRoutes from './routes/ReadAcross/ReadAcrossRoutes';
import NotFound from './components/Alerts/NotFound';
import ServerError from './components/Alerts/ServerError';
import Profile from './routes/Profile/Profile';
import ProtocolRoutes from './routes/Protocol/ProtocolRoutes';
import HelpCenter from './routes/HelpCenter/HelpCenter';
import { useEffect } from 'react';
import Unauthorized from './components/Alerts/Unauthorized';
import { useDispatch } from 'react-redux';
import { setApplication } from './store/slices/auth.slice';


function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname) {
      document.body.scrollTo(0,0);
    }
  }, [pathname]);

  useEffect(() => {
    let type = '';
    const link = document.getElementById('favicon');

    if (process.env.REACT_APP_APPLICATION) {
      type = process.env.REACT_APP_APPLICATION;

      if (process.env.REACT_APP_APPLICATION === 'megatox') {
        document.documentElement.style.setProperty('--secondary-color', '16, 130, 108');
        document.documentElement.style.setProperty('--tertiary-color', '56, 159, 139');
        document.documentElement.style.setProperty('--grad-one-color', '148, 205, 183');
        document.documentElement.style.setProperty('--grad-two-color', '185, 227, 239');
        document.documentElement.style.setProperty('--highlight-color', '34, 193, 135');
        document.documentElement.style.setProperty('--darken-color', '0, 77, 58');
        if (link) {
          (link as HTMLLinkElement).href = `${process.env.PUBLIC_URL}/favicon-mt.ico`;
        }
        
      } else if ((process.env.REACT_APP_APPLICATION).includes('assay-central')) {
        document.documentElement.style.setProperty('--secondary-color', '0, 109, 119');
        document.documentElement.style.setProperty('--tertiary-color', '12, 132, 143');
        document.documentElement.style.setProperty('--grad-one-color', '171, 206, 209');
        document.documentElement.style.setProperty('--grad-two-color', '221, 231, 179');
        document.documentElement.style.setProperty('--highlight-color', '26, 193, 163');
        document.documentElement.style.setProperty('--darken-color', '0, 76, 83');
        if (link) {
          (link as HTMLLinkElement).href = `${process.env.PUBLIC_URL}/favicon.ico`;
        }
      } else if (process.env.REACT_APP_APPLICATION === 'megatrans') {
        document.documentElement.style.setProperty('--secondary-color', '74, 72, 70');
        document.documentElement.style.setProperty('--tertiary-color', '140, 133, 125');
        document.documentElement.style.setProperty('--grad-one-color', '188, 183, 161');
        document.documentElement.style.setProperty('--grad-two-color', '221, 200, 146');
        document.documentElement.style.setProperty('--highlight-color', '195, 132, 83');
        document.documentElement.style.setProperty('--darken-color', '37, 34, 31');
        if (link) {
          (link as HTMLLinkElement).href = `${process.env.PUBLIC_URL}/favicon-mtr.ico`;
        }
      }
    } else {
      type = 'assay-central';
    }

    if (process.env.REACT_APP_META_TITLE) {
      document.title = process.env.REACT_APP_META_TITLE;
    } else {
      document.title = 'Assay Central';
    }

    dispatch(setApplication(type));
  }, [dispatch]);



  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Login />} />
        <Route path='files/*' element={<FilesRoutes />} />
        <Route path='datasets/*' element={<DatasetsRoutes />} />
        <Route path='models/*' element={<ModelsRoutes />} />
        <Route path='predictions/*' element={<PredictionsRoutes />} />
        <Route path='readacross/*' element={<ReadAcrossRoutes />} />
        <Route path='jobs/*' element={<JobsRoutes />} />
        <Route path='users' element={<UserDirectory />} />
        <Route path='profile' element={<Profile />} />
        <Route path='protocols/*' element={<ProtocolRoutes />} />
        <Route path='help/*' element={<HelpCenter />} />
        <Route path='500' element={<ServerError />} />
        <Route path='401' element={<Unauthorized />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
