import EmailSupport from '../../../assets/tutorialAssets/email-support.png';
import UserManual from '../../../assets/tutorialAssets/manual.png';


export default function QuickSupport() {

    return (
        <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-stretch py-[80px] px-10">
            <p className="font-semibold text-[2rem] mb-6">Quick Support</p>
            <div className="w-full grid grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))] grid-rows-[repeat(auto-fit,_1fr)] gap-10">
                <a href={process.env.REACT_APP_APPLICATION === 'megatox' ? `${process.env.PUBLIC_URL}/MT-Revised-User-Manual.pdf` : `${process.env.PUBLIC_URL}/AC-Revised-User-Manual.pdf`} target="_blank" rel='noreferrer' className="w-full rounded bg-white drop-shadow-default p-10 flex flex-col justify-center items-center gap-4 hover:bg-secondary/[0.2] hover:drop-shadow-none">
                    <img src={UserManual} className="w-[100px]" alt='user-manual'/>
                    <p className="text-[18px] font-medium">View User Manual</p>
                </a>
                <a href='mailto:techsupport@collaborationspharma.com' className="w-full rounded bg-white drop-shadow-default p-10 flex flex-col justify-center items-center gap-4 hover:bg-secondary/[0.2] hover:drop-shadow-none">
                    <img src={EmailSupport} className="w-[100px]" alt='ema'/>
                    <p className="text-[18px] font-medium">Email Tech Support</p>
                </a>
            </div>
        </div>
    )
}