import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import { useEffect, useState } from "react";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useLayout from "../../../hooks/useLayout";
import useEnableSelection from "../../../hooks/useEnableSelection";
import { useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import Info from '../../../assets/info-circle.svg';
import Pagination from "../../../components/Page/Pagination";
import TableControls from "../../../components/Table/TableControls";


import ToggleView from "../../../components/Page/ToggleView";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup";
import ChemicalPopup from "../../../components/Popups/ChemicalPopup";
import Grid from "../../../components/Grid/Grid";
import CreateDatasetPopup from "../../Datasets/components/CreateDatasetPopup";
import { SideInfo } from "../../../types/all.types";
import SingleCompoundOptions from "../../../components/Dropdowns/SingleCompoundOptions";
import ReadacrossPopup from "../../ReadAcross/components/ReadacrossPopup";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleFile from "../../../hooks/pageHooks/useSingleFile";
import useMessage from "../../../hooks/useMessage";
import { useSelector } from "react-redux";
import { selectApplication } from "../../../store/selectors/auth.selector";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ImageCell from "../../../components/Data/ImageCell";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualFileOptions from "../components/IndividualFileOptions";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import MetaData from "../../../components/Page/MetaData";

export default function SingleFile() {
    const { id } = useParams();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const application = useSelector(selectApplication);
    const [ isReadacrossFlow, setIsReadacrossFlow ] = useState(false);

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();
    
    const {
        orderedRecords,
        info,
        routes,
        singleFile,
        updateFile,
        options,
        deleteFiles,
        downloadFile,
        itemIdentifier,
        headers,
        setHeaders,
        modifiedRecords,
        modifyRecords
    } = useSingleFile({id, setPopupMessage});

    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, selectedHeader, setSelectedHeader })

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedRecords, count: 50});

    const { 
        isSideMenuOpen, 
        toggleSideMenu,
        isPopupOpen,
        popupType,
        setPopupType,
        togglePopup
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked, 
        toggleChecked, 
        addSingleItem,
        deselectItems,
        selectedRows
    } = useEnableSelection({allItems: undefined});



    const [ moleculeID, setMoleculeID ] = useState('');
    const [ popupInfo, setPopupInfo ] = useState<SideInfo[]>([])

    useEffect(() => {
        if (!singleFile) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [singleFile]);

    useEffect(() => {
        if (moleculeID) {
            const record = modifiedRecords.find(r => r.id === moleculeID);
            if (record) {
                let info: SideInfo[] = [];
                Object.keys(record).forEach(key => {
                    if (key !== 'id' && key !== 'Structure') {
                        info.push({
                            label: key,
                            value: !record[key] ? 'No value' : record[key]
                        })
                    }
                })

                setPopupInfo(info)
            }

            
        }
    }, [moleculeID,  modifiedRecords]);

    const goBack = (e:any) => {
        setPopupType('chemical');
        setIsReadacrossFlow(false);
    }



    return (
        <div style={{maxHeight: `${isGrid ? 'none' : 'calc(100vh - 70px)'}`}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">

                <SinglePageHeader id='single-file-header' label={singleFile ? singleFile.name : ''} isLoading={isLoading} routes={routes} metadata={singleFile && <MetaData records={singleFile.records_number}/>}>
                    <OptionsDropdownBase isLoading={isLoading} >
                        <IndividualFileOptions application={application} downloadFunc={downloadFile} setPopupType={setPopupType} togglePopup={togglePopup} id={id} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                <>
                    {/* Search Bar & Buttons Component */}
                    <TableControls hasBorder={true} applySearch={applySearch} identifier={itemIdentifier ? itemIdentifier : undefined} id='single-file-controls' toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={itemIdentifier ? searchInput : undefined}>
                        <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                        {paginatedItems.length > 0 && <ToggleView toggleView={toggleView} isGrid={isGrid} />}
                    </TableControls>
                    
                    {/* Table Component */}
                    <>
                    {isGrid ? (
                        <>
                        <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                            {paginatedItems.length > 0 ? (
                                <>
                                {paginatedItems.map(item => {
                                    let title = '';
                                    const h = headers.find(header => header.isRequired && header.label.toLocaleLowerCase() !== 'structure');
                                    if (itemIdentifier) {
                                        title = item[itemIdentifier];
                                    } else if (h) {
                                        title = item[h.value];
                                    }
                                    return (
                                        <GridItemBase
                                            title={title}
                                            optionButton={
                                            <IndividualOptionsDropdownBase isGrid={true}>
                                                <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                            </IndividualOptionsDropdownBase>                                            
                                            }
                                            isChecked={false}
                                            gridType="structure"
                                            item={item}
                                        >
                                            <button onClick={(e:any) => {togglePopup(e); setPopupType('chemical') ;setMoleculeID(item.id)}}>
                                                <ImageCell type="grid" id={item.id} />
                                            </button>
                                            <div>
                                                <div className='flex flex-col gap-4 justify-center items-stretch'>
                                                {Object.keys(item).filter(key => key !== 'Structure' && key.toLocaleLowerCase() !== 'smiles').map(key => (
                                                    <div className='flex flex-col justify-start items-start gap-[2px]'>
                                                        <p className="text-[12px] text-secondary font-semibold">{key}:</p>
                                                        <p className={`text-[14px] ${key.includes('smile') ? 'break-all' : 'break-words'} ${!item[key] ? 'text-[#a9a9a9]' : 'text-primary'} font-medium text-left`}>{!item[key] ? 'No value' : item[key]}</p>
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                        </GridItemBase>
                                )
                                })}
                                </>
                            ) : (
                                <EmptyTable isGrid={true} searchInput={searchInput} subject="This file contains no records"/>
                            )}
                        </Grid>
                        {paginatedItems.length > 0 && (
                            <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                        )}
                        </>
                    ) : (
                        <>
                        {isLoading || paginatedItems.length > 0 ? (
                            <>
                                <TableBase
                                    isLoading={isLoading}
                                    isPopupOpen={false}
                                    isChecked={false}
                                    headers={headers} 
                                    selectedHeader={selectedHeader} 
                                    setSortedColumn={setSortedColumn} 
                                    hasOptions={isLoading ? false : true}
                                >
                                    {paginatedItems.length > 0 && paginatedItems.map(item => (
                                        <TableRowBase
                                        isChecked={isChecked} 
                                        item={item} 
                                        headers={headers} 
                                        action={{togglePopup, setPopupType, setMoleculeID}}
                                        rowType="base"
                                        >
                                            <IndividualOptionsDropdownBase>
                                                <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                            </IndividualOptionsDropdownBase>
                                        </TableRowBase>
                                    ))}
                                </TableBase>
                                {paginatedItems.length > 0 && (
                                    <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                    firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                                )}
                            </>
                        ) : (
                            <EmptyTable searchInput={searchInput} subject="This file contains no records"/>
                        )}
                        </>
                        )}
                    </>

                </>
                
                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} options={options} updateFunc={updateFile} info={info} label='File Details' togglePopup={toggleSideMenu} />
            )}

            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                {popupType === 'chemical' ? (
                    <ChemicalPopup setIsReadacrossFlow={setIsReadacrossFlow} togglePopup={togglePopup} id={moleculeID} info={popupInfo} setPopupType={setPopupType} addSingleItem={addSingleItem} />
                ) : popupType === 'delete' ? (
                    <DeletePopup toggleChecked={toggleChecked} isChecked={isChecked} togglePopup={togglePopup} deleteFunc={deleteFiles} items={id ? [id] : []} label='this file' path='/files'/>
                ) : popupType === 'create-dataset' ? (
                    <CreateDatasetPopup isFlow={false} togglePopup={togglePopup}/>
                ) : (
                    <ReadacrossPopup goBack={isReadacrossFlow ? goBack : undefined} setIsFlow={setIsReadacrossFlow} togglePopup={togglePopup} deselectItems={deselectItems} items={selectedRows} />
                )}
                </Overlay>
            )}


        </div>
    )
}